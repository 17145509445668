exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-uses-tsx": () => import("./../../../src/pages/uses.tsx" /* webpackChunkName: "component---src-pages-uses-tsx" */),
  "component---src-pages-writing-tsx": () => import("./../../../src/pages/writing.tsx" /* webpackChunkName: "component---src-pages-writing-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */),
  "component---src-templates-writing-tsx": () => import("./../../../src/templates/writing.tsx" /* webpackChunkName: "component---src-templates-writing-tsx" */)
}

